/*=============================================================================================	
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet 
    Author     : Your Name
==============================================================================================*/
@import "sass-lib";

body {  
    color: #333;
    font-size: 12px;
    font-family: "Hind"; 
    position: relative;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
}
figure{
    margin: 0; padding: 0;
    img{vertical-align: middle;}
}
img{ border:0; max-width:100%; height:auto; }
p {
    margin: 1px 0 15px;
    line-height: 20px;
}
input, textarea, select {
    font-family: "Hind"; 
    font-size: 12px;
    border: none;
    border: 1px solid #D8D8D8;
    padding: 10px;
    margin: 0;
    color: #666;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    @include borderRadius(0);
}

input[type=checkbox], input[type=radio] {
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}
input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button  {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    opacity: 0;
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
h1 { font-size: 32px; }
h2 { font-size: 28px; }
h3 { font-size: 24px; }
h4 { font-size: 20px; } 
h5 { font-size: 16px; } 
h6 { font-size: 14px; }
/* structure 
----------------------------------------------------------------------------------------------*/
section { position: relative; }
.wrapper {width: 1170px;margin: 0 auto;}

.grabvibes{font-family: $grabvibes;}
.pacifito{font-family: $pacifito;}
.homemade{font-family: $homemade;}
.tangerine{font-family: $tangerine;}
.nothing{font-family: $nothing;}
.damion{font-family: $damion;}
.parisienne{font-family: $parisienne;}
.italianno{font-family: $italianno;}
.labelle{font-family: $labelle;}
.vampiroone{font-family: $vampiroone;}
.aguafina{font-family: $aguafina;}
.league{font-family: $league;}
.molle{font-family: $molle;}
.alex{font-family: $alex;}
.pinyon{font-family: $pinyon;}


.overlay{
    position: fixed;background: rgba(0,0,0,0.8);top:0;bottom:0;right:0;left:0;z-index:8;display: none;
}
/* header
----------------------------------------------------------------------------------------------*/
.burger-menu{
    position: fixed;width:40px;height: 27px;z-index:10;top:35px;left:45px;
    span{@extend .absolute-center;width:100%;height:3px;background: $red;@include transition(0.6s $ease all);
         &:nth-child(1){bottom:auto;}
         &:nth-child(3){top:auto;}

    }
    &.active{
        span:nth-child(2){opacity: 0;width:0;}
        span:nth-child(1){top:12px;@include transform(rotate(45deg))}
        span:nth-child(3){bottom:12px;@include transform(rotate(-45deg))}
    }
    &:hover{
        span:nth-child(2){right:110%;} 
    }
}

.side-menu{
    position: fixed;left:0;top:0;width:440px;height: 100%;z-index: 9;background: url(../images/material/bg-sidemnu.jpg) no-repeat;left:-440px; opacity: 0;
    nav{
        padding:145px 75px 50px;
        a{font-size:26px;font-weight:bold;color:#FFF;margin:0 0 20px 0;display: block;
          &:hover{color:$red;}
        }
    }
    .search{
        margin:0 75px;
        input{
            font-size:16px;border:none;background:none;border-bottom:1px solid #666;background: url(../images/material/search-ico.png) no-repeat right top;
            width:100%;padding:0 30px 10px 0; @include boxSizing(border-box);
        }
    }
    .lang {
        font-size: 16px; color: #666; margin: 35px 0 0 75px; display: none;
        span {display: inline-block; margin: 0 5px;}
        a {
            display: inline-block; color: #666;
            &.active,
            &:hover {color: #d31f22;}
        }
    }
}

header{
    position: fixed;height:100px;background: #FFF;@include boxShadow(0px 0px 10px -8px);width:100%;z-index: 5;top:0;
    @include boxShadow(0px 0px 20px -11px #000);
    &:after{
        position: absolute;width:1px;height:100%;background:#e5e5e5;left:129px;content:"";
				top:0; 
    }
    .logo{
        float:left;margin:29px 0 0 160px;
    }
    nav{
				margin:0 0 0 50px;
        a{
            font-weight:500;font-size:14px;color:#333;text-transform:uppercase;margin:0 24px 0 0;
            &:hover,&.active{color:$red;}
        }
    }
    .lang{
        margin:0;
				text-transform:uppercase;
				position:relative;
				padding-left: 12px;
				border-left: 1px solid #E5E5E5; 
				display: inline-block;
        a {
					font-weight:500;font-size:14px;color:#666;
					height: 20px; 
					line-height: 20px; 
					display: inline-block; 
				}
        .drop{
            position: absolute;background:$red;width:40px;height:auto;top:30px;left:-15px;padding:15px 10px;@include boxShadow(0px 0px 5px -3px);
            visibility:hidden;opacity:0;@include transition(0.2s linear all);
            a{display: block;border-bottom:1px solid #CCC;padding:0 0 10px 0;margin-bottom:10px;font-weight:bold;color:#FFF;
              &:last-child{margin-bottom: 0;padding-bottom:0;border:none;}
              &:hover{color:#ffad00;}
            }
            &:after{
                content:"";width:0;height:0;position:absolute;border-bottom:10px solid $red;border-left:10px solid transparent;
                border-right:10px solid transparent;right: -2px;margin:auto;top:-10px;left:0;right:0;
            }
        }
        &:hover{
            .drop{visibility: visible;opacity: 1;}
        }    
    }
    .search{
				margin:0;
        a.toggle-search{
            position:relative;width:20px;height:20px;display: block;text-indent:-99999px;background: url(../images/material/search-ico.png) no-repeat;

        }
    }
    .right{
        margin:0;
        a.phone{
            margin:0 12px 0 0;padding:0 0 0 26px;height:26px;line-height:26px;background:url(../images/material/phone-ico.png) no-repeat left center;
            font-size:14px;color:#666;
						background-size: 20px;
						font-weight: 700;
        }
        a.online-payment{
            margin:0 12px 0 0;padding:0 0 0 26px;height:26px;line-height:26px;background:url(../images/material/payment-ico.png) no-repeat left center;
            font-size:14px;color:#666;
						background-size: 20px;
        }
        a.btn{
            width:auto; max-width: 124px;height:32px;line-height:32px;padding:0 10px 0;@include boxSizing(border-box);font-size:12px;color:#FFF;position:relative;
            background:url(../images/material/bg-btn.jpg) repeat-x;display:inline-block;text-transform:uppercase;
						margin:0 0 0 30px;
						font-weight: 700;
            &:after{
                background:url(../images/material/acc-ico.png)no-repeat;width:28px;height:28px;content:"";position:absolute;left:20px;top:0;bottom:0;margin:auto;
								display: none; 
            }

        }
        .account-nav{
            float: left;height:85px;text-transform:uppercase;padding:0 10px 0 30px;position:relative;cursor:pointer;
            border-left:0px solid #e1e1e1;
            &::before {
              position: absolute;
              width: 1px;
              height: 28px;
              top: 0;
              left: 0;
              content: "";
              background: #e1e1e1; 
            }
            > a{
                font-size:14px;color:$red;padding:0 25px 0 40px;margin:0;display: block;height:28px;line-height: 30px;
                background: url(../images/material/accred-ico.png) no-repeat left center;font-weight: 600;position:relative; overflow: hidden;
                width: 105px; white-space: nowrap; text-overflow: ellipsis;                
                &:after{
                    content:"";width:12px;height:7px;background:url(../images/material/drop-ico.png) no-repeat ;@extend .absolute-center;left:auto;
                }
            }
            &:hover{
                .drop{visibility: visible;opacity: 1;}
            }
            .drop{
                position: absolute;width:259px;background:#e1e1e1;left:0;top:100%;visibility:hidden;opacity:0;@include transition(0.2s linear all);
                a{
                    display: block;width:auto;padding:22px 30px 18px;font-size:16px;font-weight:600;color:#111;border-bottom:1px solid #CCC;
                    &:hover{
                        color:$red;
                    }
                }
            }
        }
    }

		.header-right {
			display: flex; 
			flex-direction: column;
			margin:0 40px;
			.header-top {
				height: 32px;
				margin: 16px 0 0 0; 
				display: flex;
				justify-content: flex-end; 				
				.right {
					float: none;
					.phone-mobile {
						display: none; 
					}
				}
			}
			.header-bottom {
				margin: 16px 0 0 0;
				display: flex;
				justify-content: flex-end;  
				align-items: center;
				.nav {}
				.search {}
			}
		}

}
.wrapper{
    &:after{@extend .after_clear;}
}
/* middle
----------------------------------------------------------------------------------------------*/
.btn-border{
    height:50px;padding:0 45px;border:2px solid #FFF;font-size: 16px;line-height: 50px;color:#FFF;display:inline-block;text-transform:uppercase;text-align: center;
    @include transition(0.2s ease-out all);@include boxSizing(border-box);
    &:hover{
        background:#FFF;color:$red;
    }
    &.less-box{
        border:none;color:$orange;padding:0 30px;
        &:hover{color:#333;}        
    }

}
.btn-plus{
    width: 50px;height:50px;background:$orange url(../images/material/plus-ico.png) no-repeat center;display: inline-block;position: relative;text-align: center;
    @include boxSizing(border-box);
    span{
        width:130px;position: absolute;left:120%;background: $orange;height:50px;line-height: 50px;color:#FFF;font-size:14px;text-transform:uppercase;
        margin-left: 1px;text-align: center;@include transition(0.2s ease-out all);
    }
}
.btn-block{
    display:inline-block;font-size:16px;text-transform: uppercase;color:#FFF;padding:0 40px;height:50px;@include boxSizing(border-box);cursor:pointer;line-height: 52px;text-align: center;
    background:$orange;border:none;@include transition(0.2s ease-out all);@include boxSizing(border-box);position:relative;
    &:hover{
        background: $red;color:#FFF;
    }
    &.download-btn{
        padding:0 30px 0 80px;margin:20px 0 0 0;
        &:before{
            height:50px;width:55px;content:"";border-right:1px solid #FFF;background: url(../images/material/down-ico.png) no-repeat center;
            position:absolute;left:0;top:0;

        }
    }
}

.banner-home{
    z-index:0; background-size: cover;
    padding:0 0 60px 130px;background:url(../images/material/bg-slider.jpg) no-repeat bottom left;position:relative;margin:78px 0 0 0;
    img{display: block;position: relative;z-index: 0; width: 100%;}
    .slide{
        position: relative;
        &:after {
            display: block; content: ''; background: rgba(0,0,0,0.35); position: absolute; right: 0; top: 0; bottom: 0; left: 0; right: 0;
        }
    }
    .wrap-caption{width:1360px;@extend .absolute-center;}
    .caption{
        position: absolute;z-index:3;width:465px;height:340px;top:0;bottom:0;left:0;margin:auto;
        h1,h2{font-size: 56px;font-weight:700;color:#FFF;line-height: 56px;margin:0 0 20px 0;text-transform: uppercase}
        p{font-size: 16px;line-height: 26px;color:#FFF;margin:0 0 45px 0;}
        a{
            width:200px;height:50px;line-height:50px;padding:0 22px 0 60px;@include boxSizing(border-box);font-size:16px;color:#FFF;position:relative;
            background:url(../images/material/bg-btn.jpg) repeat-x;display:inline-block;float:left;text-transform:uppercase;margin:-15px 0 0 0;
            @include boxShadow(0px 0px 30px -10px #000);
        }
        > *{position: relative;}
    }
    .slick-dots{
        position: absolute;left:-75px;
				// bottom:45%;
				top: 50%;
				transform: translateY(-50%); 
        button{
            border:none;background:none;color:#FFF;font-size:16px;font-weight:700;padding:0 2px;position:relative;margin:0 0 50px 0;padding:0 0 5px 0;cursor:pointer;
            &:before{content:'0'}
            &:after{
                content:"";position: absolute;width:0%;background: $red;height:3px;left:0;bottom:0;@include transition(0.2s ease-out all);
            }

        }
        .slick-active{
            button:after{width:100%;}
        }

    }
    a.scroll-down{
        font-size:13px;color:#959595;padding:0 55px 0 0;height:13px;position: absolute;bottom:50px;left:55px;@include transform(rotate(-90deg) );
        @include transform-origin(0 0  );
        &:after{
            content:"";position: absolute;width:40px;background: $red;height:2px;right:0;bottom:0;top:0;margin:auto;
        }
    }
    &.banner-std{
        background: none;padding-bottom:0; position: relative;
				margin-top: 100px; 
        .caption{height:220px;left: 0;}

        .map-location{padding:0 565px 0 0 ;height: 600px;
                      >div {height: 600px}
        }
        .location-right{
            background: #4f0101;height: 100%;position: absolute;right: 0;top:0;@include boxSizing(border-box);padding:55px 55px 0;
            color:#FFF;width: 565px;
            h4{font-size: 31px;margin:0 0 50px 0;}
            p{margin:0;font-size: 16px;line-height: 23px;padding-right: 20px;
              b{display: block;margin-bottom: 5px}
            }
            .box{
                border-bottom: 1px solid #611b1b;margin-bottom: 30px;padding-bottom: 30px;
            }
            .list-loc {
                height: 405px; overflow: auto;
                .mCSB_inside > .mCSB_container {margin-right: 20px;}
                .mCSB_scrollTools {right: -3px;}
                .mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, .mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {background: #611b1b;}
            }
        }
        .scroll-down{
            bottom:-10px;
        }
        &:after {
            content: ''; position: absolute; left: 130px; right: 0; top: 0; bottom: 0; background: rgba(0,0,0,0.4);
            display: block;
        }
    }
}

.home-sec{
    z-index:0;
    h2{font-size:30px;font-weight:700;color:#111;line-height: 40px;margin:0 0 10px 0;text-transform: uppercase;}
    p{font-size: 16px;line-height: 26px;font-weight:400;margin-bottom: 25px}
    .wrapper{
        width:1360px;
    }

    &.h-about{
        max-width:1600px;margin:auto;padding:150px 0 140px;background: url(../images/content/habout.jpg) no-repeat  left bottom;
        .left{width:350px;text-align: right;
              h2{font-size:40px;line-height: 50px;color:#111;text-transform: uppercase;font-weight:700;}
        }
        .right{
            width:730px;padding-right: 30px;
        }

        .list-col{            
            margin:50px 0 0 0;
            .col{
                width:285px;float:left;margin:0 160px 40px 0;
                &:nth-child(2n){margin-right: 0;}
                &:nth-child(2n+1){clear: both;}
                h5{font-size:19px;font-weight:700;color:$red;margin:0 0 10px 0;}
                p{margin-bottom: 0;}
            }
            &:after{@extend .after_clear;}
        }
    }
    &.h-prod{
        padding:90px 0;color:#FFF;
        &:before{position: absolute;left:120px;top:0;bottom:0;right:0;background:#4f0000 url(../images/content/bg-prod.jpg) ;content:"";}
        .wrapper{position: relative;width:1480px;}
        h2{color:#FFF;}
        .slider{
            float:left;width:1020px;
            .box{
                position: relative;padding-bottom:13px;cursor:pointer;
                > * {position: relative;} 
                &:after {
                    display: block; content: ''; background: rgba(0,0,0,0.35); position: absolute; right: 0; top: 0; bottom: 13px; left: 0; right: 0; z-index: 1;
                }
                > img {width: 100%;}
            }
            h5{font-size:29px;font-weight: 300;margin:0 0 7px 0;}
            h6{font-size: 16px;font-weight:400;
               b{font-size: 19px;font-weight:500;text-transform: uppercase;padding-left: 5px}
            }
            p{margin: 0 0 30px; font-weight:400; overflow: hidden;height:0;@include transition(0.7s $ease all);}
            .text{width:255px;position:absolute;left:0;right:0;margin:auto;bottom:0px; z-index: 2;}

            .box:hover{
                p{height: 52px;margin-top: 20px;}
                a span{left:100%;opacity: 1;}
            }

        }
        .right{width:360px;padding:100px 0 0 0;}
        .slick-arrow{
            width:18px;height: 32px;background: url(../images/material/arrow-box.png) no-repeat ;opacity: 0.5;position: absolute;cursor: pointer; padding: 0;
            border:none;text-indent: -9999px;z-index: 2;top:0;bottom:0;margin:auto;
            &.slick-next{right:-45px;background-position: right}
            &.slick-prev{left:-45px;}
            &:hover{opacity: 1;}
        }
    }

    &.h-updates{
        padding:90px 0;
        .list{
            margin:30px 0 0 0;
            &:after{@extend .after_clear;}
            .box{
                float: left;width:270px;margin:0 90px 0 0;
                h6{font-size: 19px;line-height: 24px;color:#333;margin:0 0 50px 0; height: 72px; overflow: hidden;}
                &:nth-child(4n){margin-right: 0;}
                small{font-size:16px;color:#666;display: block;line-height: 24px;margin:0 0 20px 0;height: 48px; overflow: hidden; position: relative;}
                p{font-size: 14px;color:#999; border-top:1px solid #CCC; padding-top: 20px; margin-top: 0;
                  b{color:$orange;}
                }
                &:hover{
                    h6{color:$red;}
                }
            }
        }
    }

    &.h-testi{
        padding-bottom:80px;border-bottom:1px solid #eaeaea;margin-bottom:80px;
        .slider{margin-top: 30px;}
        .box{
            margin: 0 13px;background: #f1f1f1;
            .img{float:left;width:240px;} 
            .text{
                float: left;width:315px;margin:35px 0 0 48px;
                h5{font-size: 19px;font-weight:700;color:#111;margin: 0 0 5px 0;}  
                h6{font-size:16px;color:$red;margin-bottom: 10px;}
                p{color:#111;}
            }
            &:after{@extend .after_clear;}
        }
        .slick-dots{
            position: absolute;top:-30px;right:30px;
            li{float:left;}
            button{
                background: none;border:2px solid #CCC;width:10px;height:10px;float:left;display: block;text-indent:-99999px;padding:0;margin:0 0 0 10px;
                @include transition(0.3s linear all);cursor:pointer;
                &:hover{
                    border-color:$red;
                }
            }

            .slick-active button{background: $red;border-color:$red;}
        }
    }
    &.h-contact{
        padding-bottom:110px;
        .left{width:600px;
              .box{
                  height: auto;background: #4f0101;color:#FFF;padding:30px 40px;
                  h6{font-size:16px;font-weight: 700;margin:0 0 5px 0;}
                  p{margin: 0;}
              }
              #home-map{height: 500px;}
        }
        .right{
            width: 705px;
            p{margin-bottom: 40px;}
        }
    }
}


.content-std{
    padding:115px 0 ;position:relative;z-index:0;
		&.no-space-bottom {
			padding-bottom: 0; 
		}

    h2{font-size:40px;line-height: 50px;margin-bottom:10px;color:#111;text-transform:uppercase;font-weight: 700;}
    h3{@extend h2;font-size:31px;}
    p.sub{font-size:20px;line-height: 30px;margin-bottom:30px;}
    ul,ol,
    p{font-size: 16px;line-height: 26px;font-weight:400;margin-bottom: 25px}
    .wrapper{width: 1360px;}

    ul li{
        padding: 0 0 0 20px;position: relative;
        &:after{
            width:8px;height: 8px;background: $red;@include borderRadius(100%);content:"";position: absolute;left: 0;top: 8px;
        }
    }
    ol{list-style: decimal;margin: 0 0 0 20px}
    .date-list{
        display: inline-block;margin-bottom: 20px;color: #cc0001;font-size: 15px;line-height: 22px;position: relative;
        &:after{content:"";position: absolute;top: 50%;left: 100%;height: 2px;width: 45px;background: #e60012;margin-top: -3px;margin-left: 10px;}
    }
    .title-related{margin-bottom: 50px;text-align: center;}

    &.faq {padding-top: 180px;}

    &.about{
        padding:170px 0;
        h2{margin-bottom: 40px;}
        .inner-about{width:665px;}
        .side-image{
            position: absolute;height:100%;background:url(../images/material/side-about.jpg) no-repeat ;width:378px;top:0;right:0;
            background-size:auto 100% ;
            .img{
                position: absolute;width:530px;height:720px;position: absolute;top:0;bottom:0;margin:auto;left:-265px;
            }
        }

        .list-col{            
            margin:50px 0 0 0;
            .col{
                width:285px;float:left;margin:0 95px 40px 0;
                &:nth-child(2n){margin-right: 0;}
                &:nth-child(2n+1){clear: both;}
                h5{font-size:19px;font-weight:700;color:$red;margin:0 0 10px 0;}
                p{margin-bottom: 0;}
            }
            &:after{@extend .after_clear;}
        }
    }

    .list-prod{
        margin:100px 0 0 0;
        &:after{@extend .after_clear;}
        .box{
            width:395px;float:left;margin:0 65px 50px 0;cursor:pointer;
            .text{
                border:1px solid #CCC;border-top:none;padding:40px 30px 30px ;
            }
            h2{text-transform: none;font-size: 31px;line-height: 35px;font-weight: 300;max-height: 105px;overflow: hidden;margin-bottom: 20px;}
            h5{font-size:31px;line-height: 35px;font-weight:300;color:#111;margin:0 0 10px 0;max-height: 105px;overflow: hidden;}
            h6{font-size:25px;font-weight:bold;color:#000;margin:0 0 20px 0;text-transform:uppercase;
               span{font-size:16px;font-weight:300;text-transform: capitalize;}
            }
            h4 {font-size: 20px; font-weight: 300; line-height: 26px; margin-bottom: 20px;}
            p{margin-bottom: 20px; height: 104px; overflow: hidden;color: #6f6e68;max-height: 78px;overflow: hidden;}
            &:nth-child(3n){margin-right: 0;}
            .text .btn-plus span{left:100%;opacity: 1; margin-left: 0;}
            .text .btn-plus{width: 0;}
            &:hover{
                .text .btn-plus span{background: $red; color: #fff;}
            }
            .img {
                img {display: block; width: 100%;}
            }
        }
        &.nomargtop{margin-top: 0;}
        &.news {
            .box {
                margin-bottom: 60px;
                h4 {height: 52px; overflow: hidden;}
                .btn-plus {margin-bottom: -50px;}
            }
        }
    }

    &.content-detail{
        .wrapper{width:920px;}
        hr{border:none;border-bottom: 1px solid #ccc;background: none;margin:70px -95px;}
        ol,li,
        p{font-size: 20px;line-height: 30px;}
        .title{
            margin:0 0 50px 0; 
            small{
                font-size: 13px;
                &.tgl {text-align: center; display: block;}
                i { display: inline-block; margin: 0 15px; font-style: normal; }
            }
            h2{margin-top: 15px}
            h4 {font-size: 26px; text-transform: uppercase; font-weight: 700;}
            &.little {
                margin: 20px 0 30px;
            }
        }

        .image-slider,.image-content{
            margin:0 -95px 65px;
            .slick-dots{
                position: absolute;bottom:20px;text-align: center;left: 0;right: 0;margin: 0;
                li{display: inline-block;margin:0 5px;padding: 0;&:after{display:none;}}
                button{border:none;background: none;@include boxSizing(border-box);border:2px solid #c8c8c8;width: 10px;height: 10px;
                       padding:0;text-indent: -9999px;@include borderRadius(0);cursor: pointer;
                       &:hover{border-color:$red;}
                }
                .slick-active{
                    button{border-color:$red;background: $red;}
                }

            }
        }
        .box-inquiry{
            margin:80px -95px 0;position: relative;
            .text{
                position: absolute;width: 380px;left: 95px;top:35px;color:#FFF;
                small{font-size: 13px}
                p{margin: 30px 0 0 ;}
                h2{color:#FFF;margin:0 0 15px 0;
                   span{font-size: 13px;font-weight: 400}
                }
                a.btn-border,
                a.btn-block{width: 175px;padding:0;float: left;text-align: center;margin-right: 20px}
                a.btn-block{margin-right: 0;}
            }
        }
        .nav-sub{
            text-align:center;font-size:20px;margin:0 0 95px 0;
            a{color:#333;display:inline-block;
              &:hover{color:$red;}
              &.active{color:$red;font-weight:bold;}
            }
            span{
                display: inline-block;width:1px;height: 25px;background:#888;position:relative;margin:0 25px;bottom:-6px;
            }
        }
        #map-location-det{height: 500px;}
        .list-gallery{
            margin:0 -95px;
            .box{
                position: relative;float:left;width:350px;height:300px;margin: 0 30px 30px 0;cursor:pointer;overflow:hidden;
                > img {width:100%;@include transition($ease 0.4s  all); position: relative; width: 100%; height: 100%; object-fit: cover;}
                &:nth-child(3n){margin-right: 0;}
                &:after{
                    content:"";@extend .absolute-center;background:url(../images/material/gallery-gradient.png) repeat-x bottom center;z-index: 0;
                }
                h5{font-size:23px;bottom:25px;left:25px;font-weight:bold;position:absolute;color:#FFF;z-index: 1;}
                &:hover{
                    img{@include transform(scale(1.1))}
                }
            }
            &:after{@extend .after_clear;}
        }

    }
    &.nopaddtop{padding-top: 0;}
    &.nopaddbot{padding-bottom: 0;}
    .list-location{
        margin-bottom: 50px;
        .box{
            margin:0 0 30px 0;border:1px solid #CCC;
            .img{float: left;margin:-1px;overflow: hidden;width: 760px;
                 img{display: block;}
            }
            .text{
                float: left;padding: 50px 60px 0;@include boxSizing(border-box);width: 600px;
                h4{font-size: 31px;margin:0 0 15px ;}
                small{font-size: 16px;display: block;margin-bottom: 15px;}
                p{margin-bottom: 20px;}
            }
            &:after{@extend .after_clear;}

        }
    }

    .box-contact{
        border:1px solid #CCC;margin-top: 80px;
        &:after{@extend .after_clear;}
        .left{
            min-height: 786px;width: 505px;background: url(../images/content/bg-contact.jpg) no-repeat;background-size: cover;
            @include boxSizing(border-box);padding: 70px 50px;color: #FFF;
            h4{font-size: 31px;margin:0 0 15px 0;}
            small{display: block;margin:0 0 10px 0;font-size: 16px;
                  &:after{content:"";display: block;width: 50px;height: 2px;background:$red;margin: 20px 0;}
            }
            p{font-size: 20px;line-height: 30px;
              span{display: block;margin-bottom: 10px;}
              a{color:#FFF;}
              b{padding-right: 5px;}
            }
        }
        .right{
            width: 695px;float: left;padding:65px 0 0 95px;
            h3{font-size: 30px;font-weight: 700;color:#111;margin-bottom: 20px;text-transform: uppercase;}
        }
    }
    .price-type{
        .box{
            width: 320px;float:left;border:1px solid #CCC;margin:0 23px 45px 0;
            h5{font-size:20px;font-weight: 600;margin:10px 0 0 ;text-transform: none; line-height: 25px; padding: 0 6px; white-space: normal;}
            small{font-size: 16px;display: block;}
            .head{
                height:135px;background:#719479;color:#FFF;padding:40px 35px 0;@include boxSizing(border-box);margin:-1px -1px 35px;
                h4{font-size: 31px;margin:0 0 5px 0;font-weight:300;}
                small{text-transform: uppercase}
            }
            .price-box{
                text-align: center;margin:0 0 35px 0;
            }
            .foot{
                background:#f0f0f0;padding:25px 0;text-align: center;
            }
            &:nth-child(4n){margin-right: 0;}
            &.type-2{
                .head{background: url(../images/material/gradient-1.jpg) no-repeat;background-size: cover;}
            }
            &.type-3{
                .head{background: url(../images/material/gradient-2.jpg) no-repeat;background-size: cover;}
            }
            &.type-4{
                .head{background: url(../images/material/gradient-3.jpg) no-repeat;background-size: cover;}
            }
        }
        &:after{@extend .after_clear;}
    }

    .std-table{
        width:100%;margin:0 0 85px 0;
        th,td{
            padding: 15px 20px;border:1px solid #CCC;
            &:first-child{padding-left: 30px;}
        }
        span.bullet{display: inline-block;width:8px;height:8px;background: $red;@include borderRadius(100%);}

        thead{
            th{vertical-align:middle;text-align:center;background:#333; font-size:16px;color:#FFF;font-weight:bold;
               span{
                   font-size:13px;line-height: 20px;color:#ccc;display: block;font-weight:400;margin:5px 0 0 0;  
               }
            }
        }
        tbody{
            td{
                font-size:16px;text-align: center;
                &:first-child{text-align: center;}
            }
            tr:nth-child(even){
                td {background: #eaeaea;}
            }
        }
        &.facility{
            thead {
                th {
                    font-weight: 400; text-align: center;
                    &:first-child {width: 130px; text-align: left;}
                }
            }
            tbody {
                td {
                    h5 {font-size: 16px; font-weight: 700; margin-bottom: 5px;}
                    span {font-size: 14px;}
                }
            }
        }
    }
    .wrap_tbl {
        overflow: auto;
        .std-table{
            &.facility {width: 1200px;}
        }
    }
    .support-facility{
        margin-bottom: 30px;
        .left{
            width:760px;
        }
        .right{
            width:555px;border:1px solid #CCC;@include boxSizing(border-box);padding:45px;
            p {
                &.nt {
                    span {display: block;}
                }
            }
        }
        &:after{@extend .after_clear;}

    }
    .head-log{
        display: block; position: relative;
        ul{
            display: block;
            &:after{@extend .after_clear;}
            a{
                font-size: 16px; line-height: 50px; color: #b8b8b8; font-weight: bold; @include transition($ease 0.1s  all);
                li{
                    width: 50%; margin: 0; padding: 0; float: left; text-align: center; border-bottom:  1px solid #b8b8b8; @include transition($ease 0.4s  all);
                }
                &.active, &:hover{
                    color: #870000; @include transition($ease 0.1s  all);
                    li{
                        border-bottom:  2px solid #870000; @include transition($ease 0.4s  all);
                    }
                }
            }
        }
    }
    .accordion{
        border:1px solid #CCC;margin:-1px 0 0px 0;
        .h-acc{
            border-bottom: 1px solid #CCC;padding:25px 80px 25px 60px;cursor:pointer;position:relative;margin:-1px;@include transition(0.15s linear all);
            h5{font-size:20px;font-weight:bold;margin:0 0 8px 0;}
            small{font-size:16px;}
            &:before{
                content:"";width:50px;height:50px;background: #ff7200;position:absolute;top:0;bottom:0;right:20px;margin:auto;
            }
            &:after{
                content:"";width:10px;height:10px;background:url(../images/material/acc-arr.png) no-repeat top;position:absolute;top:0px;right:40px;bottom: 0;margin:auto;
                @include transition(0.15s linear all);
            }
        }
        .c-acc{
            padding: 40px 65px;display:none;
            h6{font-size:20px;color:$red;font-weight: bold;margin:0 0 15px ;}
            a.btn-block.red-btn{
                background: $red;
                &:hover{background: #ff7200;}                
            }
        }
        ul li,ol li, p{font-size: 16px;line-height: 26px;}
        &:after{@extend .after_clear;}
        &.expand{
            .h-acc{
                background: #ff7200;color:#FFF;
                &:after{background-position: bottom center;}
            }
        }
    }
    .wrap-acc{margin-top: 80px;}

    .service-room-list{
        margin:65px -95px  ;
        .box{
            padding:50px 0;border-top:1px solid #CCC;
            small{font-size: 13px;display: block;margin:0 0 10px 0;}
            h2{margin-bottom: 30px;}
            .img{float: left;width:340px;position:relative;
                 .price-text{position: absolute;bottom:30px;left:20px;color:#FFF; z-index: 2;}
                 small{margin-bottom: 0;font-size: 20px;}
                 h5{font-size: 33px;font-weight:bold;margin:0;text-transform: uppercase;margin-bottom: 3px;}
                 &:after {z-index:1;content: ''; display: block;background: rgba(0,0,0,0.35);position: absolute;left: 0;right: 0;top: 0;bottom: 0;}
                > img {display: block;}
            }
            .text{
                float:left;width:445px;margin-left: 38px;
            }
            .wrap-btn{
                width: 190px;
                .btn-border,.btn-block,{width:190px;text-align: center;margin-bottom: 10px;}      
                .btn-border{
                    border-color:$orange;color:$orange;
                    &:hover{background: $red;color:#FFF;border-color:$red;}   
                    &.no-hover{border-color:$orange;color:$orange; background: #fff;}     
                }
                .btn-block{
                    &.red-btn{
                        background: $red;
                    }
                    &.no-hover{
                        &:hover{background: $orange;}
                    }
                }
            }
            &:after{@extend .after_clear;}
        }
    }
    &.buy-step{
        .wrapper{
            width:1110px;
        }
        .title{max-width:790px}
        .col-option {
            margin: 0 0 50px 0; font-size: 20px;
            label {display: block; margin: 0 0 22px 0;}  
            .col {
                @include clearfix(); 
                .input-radio { 
                    float: left; line-height: 20px; font-size: 20px; vertical-align: middle; position: relative;                     
                    input[type="radio"] { 
                        position: absolute; left: 0; top: 0; cursor: pointer;  
                        display: inline-block; vertical-align: middle; width: 100%; height: 16px;  
                        background: url('../images/material/ico-radio.png') no-repeat 0 0; 
                        padding: 0; margin-right: 10px; cursor: pointer; 
                        &:checked {
                            background-position:0 -16px;   
                        }
                    }
                    span {padding: 0 0 0 25px; display: block; line-height: 18px;}  
                    &.space {margin-right: 30px;}
                }
            }
        }
        .wrap-step{
            margin:0 0 130px;
            .box{
                width:277.5px;float:left;position:relative;
                .ico{width:75px;height:75px;background:#e1e1e1;margin:auto;position: relative;@include borderRadius(100%);position:relative;z-index:1;
                     span{display: block;width:75px;height:75px;}
                }
                h6{font-size:19px;line-height: 24px;color:#999;text-align: center;padding:0 50px;margin:35px 0 0 0;}
                &:after{
                    width:100%;left:50%;position:absolute;height:7px;background: #e1e1e1;content:'';top:37px;
                }
                &:last-child:after{display: none;}
                &.active .ico{@include transform(scale(1.3))}
                &.complete{
                    .ico{
                        background: url(../images/material/bg-btn.jpg) repeat-x;background-size: auto 100% ;
                        span{background-position: bottom center !important; } 
                    }
                    &:after{
                        background:$red;
                    }
                }
            }
            &:after{@extend .after_clear;}
        }
        .btn-next{
            margin:60px 0 0 0 ;
            .btn-block{
                width:280px;padding:0;
                &.grey-btn{
                    background: #9f9f9f;
                }
            }
        }
        .box-form{
            width:730px; margin-top:120px;
            .col{width:342px; position: relative;}
            .row.d-signature{
                margin:0 0 70px 0;
                label{color:#111;margin-bottom: 20px}
            }
            input[readonly]{border:none;}

        }
        .content_order {
            &:after {content: ''; display: block; clear: both;}
        }
        .left-content{
            width:790px;
            h3{
                margin:85px 0 45px 0;font-size:23px;padding:0 0 0 20px;position: relative;line-height: 45px;
                &:after{
                    content:"";width:6px;height:40px;background: #850202;position: absolute;left:0;top:0;
                }
                &:first-child{margin-top: 0;}
                /*&:nth-child(3n){margin-top: 0;}  */
            }          
            .box-form{margin:0;}
        }
        .right-content{
            width:270px;
            &:nth-of-type(2) {
                .box {padding: 12px 15px;}
            }
        } 
        .box-red{
            background:#9b0101;color:#FFF;
            h5{font-size:19px;color:#FFF;padding:15px 10px 10px;border-bottom:1px solid #aa0507;text-align: center;} 
            h6{font-size: 16px;font-weight:bold;margin:0 0 20px ;}
            .box{
                padding:30px 15px;border-bottom:1px solid #aa0507;
                &:after{@extend .after_clear;}
                .left{width:45%;
                      font-size: 14px;line-height:18px;
                      small{font-size:11px;display: block;margin-top: 3px}
                }
                .right{
                    width:55%;font-size:16px;font-weight:bold;text-align:right;
                    small{font-size:14px;display:block;margin:5px 0 0 0;}
                }
                &:last-child{border:none;}
            }
            table{
                font-size:14px;line-height:22px;
                td:nth-child(2){padding:0 10px}                    
            }
            .det-info{
                padding:20px 10px 20px;
                small{font-size:14px;}
                h3{font-size:33px;color:#FFF;margin:10px 0 0 0;line-height:26px;
                   span{font-size: 16px;font-weight: 400;text-transform: capitalize}
                }
                &.qty{
                    h2 span{font-weight: bold;}
                }
            }
            &:nth-of-type(2){
                .box {padding: 12px 15px;}
            }
            &.total {
                .box {padding: 12px 15px;}
            }
        }
        .box-red.grey-type{
            margin-top: 30px;color:#111;background:#ebebeb;
            h5{color:#111;border-color:#dcdcdc;}
            .box{
                border-color:#dcdcdc; padding: 30px 15px;
            }
            .row{
                font-size:14px;margin:0 0 20px 0;line-height:20px;
                span{display:block;color:#888888;margin-bottom:3px}
            }
        }

        .pasal{
            margin-bottom:80px;
            p{
                b{font-weight: bold;color:#111;display: block;margin:0 0 3px 0;}
            }
            ol,ul{margin-left: 30px;}
        }

        .payment-option{
            margin:0 0 105px 0;
            a{
                width:350px;float:left;height:195px;border:1px solid #CCC;position:relative;margin-right:25px;@include transition(0.2s linear all); 
                &:last-child{margin-right: 0;}
                span.ico{@extend .absolute-center;width:90px;height:60px;background-position:top center !important;top:48px;bottom:auto;}
                span.text{position: absolute;text-align:center;font-size:19px;color:#b2b2b2;bottom:45px;width:100%;}
                &:hover{border-color:$orange;}
                &.active{
                    border-color:$orange;background:$orange;
                    span.ico{background-position: bottom center !important;}
                    span.text{color:#FFF;}
                }
                label{@extend .absolute-center;cursor:pointer;}

            }
            &:after{@extend .after_clear;}
        }
        .payment-detail{
            display:none;
            p{font-size: 20px;line-height: 30px}
            .box-inner{
                margin:60px 0 60px 65px;
                img{margin-bottom: 10px}
                table,
                p{font-size: 18px;line-height: 25px;font-weight:600}
                table{
                    color:$red;
                    td:nth-child(2){padding: 0 20px}
                }
            }
        }
    }

    .tbl-std{
        position:relative;width:100%;
        h6{font-size:19px;font-weight:bold;color:#111;margin-bottom: 10px;}
        p{font-size: 16px;line-height: 20px;}
        i{display: block;padding: 5px 0 0 20px;}
        a.delete{
            display: inline-block;width:40px;height:30px;background:#9f9f9f url(../images/material/delete-ico.png) no-repeat center;text-indent:-9999px;
            &:hover{
                background-color:$orange;
            }
        }
        thead{
            tr{
                background:#e1e1e1;
                th{font-weight:600;font-size:16px;padding:15px 20px;}
            }            
        }
        tbody{
            tr{
                position:relative;
                td{
                    border:1px solid #CCC;border-right:1px solid #efefef;position:relative;padding:30px 15px;vertical-align:middle;
                    font-size: 14px;
                    &:first-child{
                        padding:30px;max-width: 285px;
                        &:after{border-left:8px solid #850202;content:"";@extend .absolute-center;}
                    }
                    &:last-child{}

                }
                &:first-child td{border-top:none;}
                &.space td{
                    border:none;padding: 0;height:20px;
                    &:after{display: none;}       
                }
            }

        }
        &.table-history{
            text-align: center;
            td{padding:20px 12px;font-size:16px;
               &:first-child{cursor: pointer;}
            }
            a{text-decoration: underline}
            .btn-block{
                width:135px;height:40px;line-height: 42px;font-size:14px;text-decoration: none;padding:0;
                &.red-btn{background: $red;}
            }
        }
    }
    .counter-num{
        position:relative;width:60px; margin: 0 auto;
        input{height: 30px;width:100%;padding:0 30px 0 10px;font-size:16px;color:#111;@include boxSizing(border-box);}
        span{
            position: absolute;width:20px;height:13.5px;position:absolute;top:0;right:0;border:1px solid #CCC;cursor:pointer;
            &:after{background: url(../images/material/updown-ico.png) no-repeat;content: "";width:8px;height:5px;@extend .absolute-center;}
            &.down{
                bottom:0;top:auto;
                &:after{
                    background-position: bottom;
                }
            }
        }
    }

    .box-register{
        width:1110px;position:relative;border:1px solid #CCC;margin:60px auto;
        a{font-size: 13px;}
        .left{
            @extend .absolute-center;background: url(../images/content/register.jpg) no-repeat;width:420px;height:100%;left:0;right:auto;
            img{@extend .absolute-center;}

        }
        .right{
            padding:60px 95px;float:right;margin:0 0 0 420px;width:505px;
            .row-btn{
                margin-top: 60px;text-align:center;
                .btn-block{width:100%;margin:0 0 10px 0;}
                a{color:$red;text-decoration: underline;}
            }
            .box-form{
                .col{width: 50%;margin:0;}
                .row {
                    &:last-of-type {margin-bottom: 0;}
                }
            }
        }
        &:after{@extend .after_clear;}
    }

    .search-page{
        width: 730px;margin:70px auto 60px;
        .title{margin-bottom: 80px;}
        .list-search{
            .box{
                h6{font-size: 23px;margin-bottom: 15px;}
                padding-bottom:40px;margin-bottom:40px;border-bottom:1px solid #CCC;
                p{margin: 0;}
                a{color:#111;}
                &:hover{
                    h6{color:$red;}
                }
            }
        }
    }
    &.account-page{
        min-height: 89vh; @include boxSizing(border-box);
        .wrapper{margin:60px auto 100px;}
        .title{margin-bottom: 60px}
        .table-history{margin-bottom: 60px}
    }
    &.faq {
        .wrap_block {
            margin-bottom: 150px;
            .left_text {
                float: left; width: 28%; 
                h2 {
                     padding: 5px 17% 5px 30px; position: relative;
                     &:before {
                        content:''; top: 0; bottom: 0; width: 5px; background: #850202; position: absolute; left: 0;
                     }
                }
            }
            .right_text {
                float: right; width: 70%;
                .wrap-acc {
                    margin-top: 0;
                }
            }
            &:after{@extend .after_clear;}
        }
        .accordion .h-acc h5 {margin-bottom: 0;}
    }    
    &:after{@extend .after_clear;}
}
.opt-checkbox {
    &:after {@extend .after_clear}     
    .item {
        float: left; margin: 0 25px 0 0; 
        &.col-4 {width: 23%; margin-right: 2.5%;} 
        &:last-child {margin-right:0;} 
        label {
            float: none; height: auto; color: #666; display: block; 
            text-align: center; position: relative; padding: 20px 30px;                     
            input {
                position: absolute; width: 100%; height: 100%; left: 0; padding: 0; cursor: pointer; 
                border:1px solid #ebebeb; top: 0; 
                &:checked {
                    background:url('../images/material/icon-check-lg.png') no-repeat 102% 102%; 
                    border-color: #850202; 
                    background-size: 10px 10px;
                }
            }
            p {
                font-size: 14px;font-weight: normal;font-style: normal;font-stretch: normal;
                line-height: 20px;letter-spacing: normal;text-align: center;color: #666;
                margin: 0; 
            }
        }
        label.error {
            position: absolute; bottom: -24px; padding: 0; left: 0; width: 100%; 
            @include boxSizing(border-box);   
        }
    }
    &.md {
        .item {
            margin-right: 10px;
            label {
                padding: 8px 35px; 
                label.error {
                    padding: 0; 
                }
            }                        
        }
    }
} 
.tagg {
    padding-left: 70px; position: relative; margin-top: 50px;
    .lbl {font-size: 20px; font-weight: 700; position: absolute; left: 0; top: 10px;}
    ul{
        li {
            padding-left: 0; display: inline-block; margin: 0 5px 5px; line-height: normal !important;
            &:after {display: none;}
            a {
                padding: 5px 15px; font-size: 18px; color: #666; border: 1px solid #ccc; display: block; max-width: 105px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; text-align: center; min-width: 80px; @include borderRadius(3px);
                @include transition(all ease-out 0.2s);
                &:hover { @include transition(all ease-out 0.2s); background: #ccc; color: #fff;}
            }
        }
    }
}

.thanks-page{
    width:1110px;border:1px solid #CCC;margin:300px auto 200px;
    .ico{
        width:240px;height: 240px;border:4px solid #009532;@include borderRadius(100%);margin:-123px auto 0;background: #FFF;position:relative;
        .ico-1{position: absolute;top:0;right:-75px;} 
        .ico-2{@extend .absolute-center;}
    }
    .text{
        padding: 100px;text-align: center;
        h5{font-size: 40px;font-weight: bold;text-transform:uppercase;margin:0 0 40px 0;}
        p{font-size: 23px;line-height: 30px;}
    }
    .wrap-btn{
        margin-top: 50px;
        a{margin-bottom:10px;}          
    }
}

.pg_404 {
    margin: 240px auto 100px; text-align: center; min-height: 55vh;
    .text {
        width: 700px; margin: 0 auto;
        h5 {font-size: 40px; line-height: 50px; margin: 60px 0 30px;}
        p {font-size: 20px; line-height: 30px;}
        .bk_index {
            font-size: 18px; line-height: 24px; text-transform: uppercase; color: #ff7200; margin-top: 40px; display: inline-block;
            &:hover {color: #d31f22;}
        }
    }
}


input[type=number]{
    @include appearance(textfield);
}

.box-form{
    input[type=text],
    input[type=password],
    input[type=number],
    input[type=email],
    select,
    textarea,
    .select2-container--default .select2-selection--multiple{
        border:none;border-bottom: 2px solid #CCC;height: auto;padding:0 0 5px 0;@include transition(0.2s linear all);width:100%;font-size:16px;color:#111;
        @include borderRadius(0);
        &:focus{border-bottom-color: #4f0101;}
        &.min-order{
            position: relative; padding-left: 50px; box-sizing: border-box;
        }
    }
    select{
        padding-right: 30px;background: url(../images/material/arr-select.png) no-repeat right;
    }

    @include placeholder{color:#111;}
    .date{
        input{background: url(../images/material/date-ico.png) no-repeat right 3px;}
    }
    label{
        display: block;font-size:16px;color:#666;margin:0 0 10px 0;
        &.bhn-text{position: absolute; top: 50%; transform: translateY(-60%); left: 0;z-index: 9;}
    }
    .row{
        margin:0 0 35px 0; position: relative;
        .col{
            width:325px;float:left;margin-right: 45px; position: relative;
            &:nth-child(2n){margin-right: 0;}
            &.down-pay{margin-top: 30px;}
        }
        &:after{@extend .after_clear;}
    }
    .row.col-3{
        .col{width:230px;margin-right: 20px;
             &:nth-child(3n){margin-right: 0;}

        }
    }
    li{padding: 0;
       &:after{display: none;}
    }
    .select2-container--default .select2-selection--multiple {
        .select2-selection__choice{
            @include borderRadius(0);background:#e1e1e1;font-size:16px;border:none;padding:5px 50px 4px 15px;position:relative;margin-right:10px;
            .select2-selection__choice__remove{background: url(../images/material/close-ico.png) no-repeat;width:8px;height:8px;position:absolute;right:10px;
                                               top:0;bottom:0;margin:auto;text-indent: -99999px;
            }
        }
        .select2-selection__rendered{padding: 0;}
    }
    .checkbox{
        font-size: 14px;color:#111;position:relative;padding:0 0 0 22px; line-height: 14px; display: block;
        input[type=checkbox]{@include appearance(checkbox);position: absolute;left:0;top:-1px; padding: 0; width: 14px; height: 14px;}

    }
    .input_file {
        border:none;border-bottom: 2px solid #CCC;height: auto;padding:0 0 5px 0;@include transition(0.2s linear all);width:100%;font-size:16px;color:#111;
        @include borderRadius(0); background: url('../images/material/ico_file.png') no-repeat 97% center; background-size: 20px;
        input[type="file"] {width: 100%; @include boxSizing(border-box); padding: 0 0 5px;}
    }
    i.ket {font-size: 13px; display: block; margin-top: 5px; color: #d31f22;}
    .note_validat {color: #fff; padding: 5px 10px; background: #4f0101; position: absolute; top: 100%; font-weight: 300; font-size: 12px; left: 0;}
    .note_min{color: #b1b1b1; padding: 5px 10px; position: absolute; top: 100%; font-weight: 300; font-size: 12px; right: 0;}
    .view {
        margin: 15px 0 20px;
        .img {
            width: 75px; height: 75px; display: inline-block; vertical-align: bottom;
            img {display: block; height: 100%;}
        }
        .link {
            display: inline-block; margin-left: 15px;  margin-bottom: 5px; vertical-align: bottom;
            a {
                font-size: 13px; font-weight: 500; color: #d31f22; display: block; margin-top: 10px;
                &:first-child {margin-top: 0;}
                &:hover {
                    color: #850202; text-decoration: underline;
                }
            }
        }
        .ico_pdf {
            width: 50px; display: inline-block; vertical-align: top; height: 75px; 
            img {width: 100%; height: 100%; display: block;}
            + .link {vertical-align: bottom;}
        }
    }
}
.select2-results__option{font-size: 16px;}

div#ui-datepicker-div {
    margin-top: -95px;
}
.wrap-popup{
    @extend .absolute-center;position: fixed;width:100%;height:100%;overflow: auto;background:rgba(0,0,0,0.9);z-index:9999;display:none;
    .overlay-pop{@extend .absolute-center;}
    .box-popup{
        width:920px;margin:auto;position:relative;margin:10% auto;
        h4{font-size:40px;font-weight: bold;color:#FFF;margin:0 0 30px 0;text-transform:uppercase;}
        a.close{
            width:68px;height:68px;background:#4f0101 url(../images/material/close-pop.png) no-repeat center;@include transition(0.7s $ease all);
            position:absolute;top:-34px;right:-34px;@include borderRadius(100%);text-indent:-9999px;z-index:1;
            &:hover{
                @include transform(rotate(180deg))
            }
        }
        .images-set{
            position: relative;
            img{position: relative;z-index: 0 !important;}        
        }
        .arrow-nav{
            position: absolute;width:29px;height:55px;background:url(../images/material/pop-nav.png) no-repeat;opacity:0.7;text-indent:-9999px;
            top:0;bottom:0;margin:auto;
            &.next{background-position: right center;right:-75px;}
            &.prev{background-position: left center;left:-75px;}
            &:hover{opacity:1;}
        }
    }
}

.box-signature{
    position: relative;
    #sig{width: 730px; max-width: 100%; height: 170px; @include borderRadius(5px); border: 1px solid #cacaca; overflow: hidden; @include boxSizing(border-box);}
    .textsig{
        position: absolute; top: 0; left: 0; right: 0; bottom: 0; padding: 50px 20px 0; display: none; @include boxSizing(border-box); background: $white; border: 1px solid #cacaca; 
        &:before{content: ""; position: absolute; top: 0; left: 0; right: 0; bottom: 0;}
        input[type=text], span{font-size: 65px; background: transparent;width: 100%; border: none; vertical-align: top; display: block; text-align: center;}
        &[data-font=grabvibes]{
            input[type=text], span{@extend .grabvibes;}
        }
        &[data-font=homemade]{
            input[type=text], span{@extend .homemade;}
        }
        &[data-font=pacifito]{
            input[type=text], span{@extend .pacifito;}
        }
        &[data-font=tangerine]{
            input[type=text], span{@extend .tangerine;}
        }
        &[data-font=nothing]{
            input[type=text], span{@extend .nothing;}
        }
        &[data-font=damion]{
            input[type=text], span{@extend .damion;}
        }
        &[data-font=parisienne]{
            input[type=text], span{@extend .parisienne;}
        }
        &[data-font=italianno]{
            input[type=text], span{@extend .italianno;}
        }
        &[data-font=labelle]{
            input[type=text], span{@extend .labelle;}
        }
        &[data-font=vampiroone]{
            input[type=text], span{@extend .vampiroone;}
        }
        &[data-font=aguafina]{
            input[type=text], span{@extend .aguafina;}
        }
        &[data-font=league]{
            input[type=text], span{@extend .league;}
        }
        &[data-font=molle]{
            input[type=text], span{@extend .molle;}
        }
        &[data-font=alex]{
            input[type=text], span{@extend .alex;}
        }
        &[data-font=pinyon]{
            input[type=text], span{@extend .pinyon;}
        }

        &.active{display: block;}
    }
    .edit{position: absolute; top: 6px; right: 10px; width: 30px; height: 30px; background: url('../images/material/ic_edit.png') no-repeat center white;cursor: pointer; @include borderRadius(10px);}
    .typeselect{
        position: absolute; bottom: 6px; right: 1px; background: #FFF; padding: 13px 20px; @include borderRadius(5px);
        a{
            display: inline-block; padding: 4px 12px 4px 0; border-right: 1px solid #d4d4d4; font-size: 16px; color: #888888; cursor: pointer;
            &:last-child{border-right: none; padding-right: 0; padding-left: 12px;}
            &:hover, &:active, &.active{color: #0052c2;}
        }
    }
}

.box-textsignature{
    position: relative;
    span{position: absolute; top: 100%; left: 0; font-size: 14px; background: #4f0101; padding: 10px 15px; color: $white; opacity: 0; visibility: hidden; @include transition(0.2s all ease-out);}
}

.popwp{
    position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.9); z-index: 1000000000; display: none;
    .in-popwp{
        background: $white; @include borderRadius(5px); padding: 30px 20px; border: 1px solid #cacaca; width: 730px; max-width: 90%; height: auto; margin: auto; position: absolute; top: 50%; left: 0; right: 0; @include transform(translateY(-50%));
        @include boxSizing(border-box);
        .ttl-pop{
            padding: 8px 15px 32px; position: relative; border-bottom: 1px solid #c6c6c6; margin-bottom: 42px;
            &:before{content: ""; position: absolute; top: 0; left: 0; width: 6px; height: 40px; background: #850202;}
            h3{font-size: 23px; color: #111111; font-weight: 700;}
            .btn-close{
                width: 18px; height: 18px; position: absolute; top: 5px; right: 0; cursor: pointer;
                span{
                    display: inline-block; position: absolute; top: 50%; left: 0; right: 0; height: 3px; background: #b2b2b2; @include transform(rotate(45deg)); @include borderRadius(3px); @include transition(0.2s background ease-out);
                    &:last-child{@include transform(rotate(-45deg));}
                }

                &:hover{
                    span{background: #111;}
                }
            }
        }
        .ctn-pop{
            padding: 0 40px; overflow: auto; max-height: 60vh;
        }
    }
}


.share {
    padding: 0 0 0 10px; background: #ebebeb; display: inline-block; @include borderRadius(3px);
    span {display: inline-block; line-height: 35px; margin-right: 12px; font-size: 16px; color: #666; text-transform: capitalize;}
    .sos {
        display: inline-block; vertical-align: middle; background: #b7b7b7; padding: 0 8px; @include borderRadius(0 3px 3px 0); position: relative;
        a {line-height: 35px; display: inline-block;  width: 23px; vertical-align: middle; margin: 0 2px;}
        &:before {
            width: 0; position: absolute; left: -6px; border-top: 6px solid transparent;border-bottom: 6px solid transparent; border-right: 6px solid #b7b7b7;
            content: ''; top: 12px; 

        }
    }
}
.sig-lst{
    margin: 0 -15px;
    &:after{content: ""; clear: both; display: block;}
    .ls-sig-lst{
        width: 33.3333333333333333%; float: left; @include boxSizing(border-box); padding: 0 15px; margin-bottom: 35px;
        .lssig{
            padding: 10px; border: 1px solid transparent; font-size: 30px; text-align: center; color: #111111; @include transition(0.2s border ease-out); cursor: pointer;
            font-family: attr(data-font); overflow: hidden;
            &:hover, &.active{border-color: #850202;}
        }
    }
}

.src_result {margin: 200px auto 150px; min-height: 59vh;}
.src_form {
    display: block; position: relative; margin-bottom: 100px;
    form {
        input[type="text"] {width: 100%; padding: 10px 50px 10px 60px; font-size: 18px; background: #f5f5f5; border-color: #f2f2f2; color: #333; font-weight: 500; @include boxSizing(border-box);}
        input[type="submit"] {position: absolute;left: 0; width: 50px; top: 0; bottom: 0; cursor: pointer; background: url(../images/material/search-ico.png) no-repeat center; border: none;}
        @include placeholder {color: #ccc;}
    }
}
.wrap_src {
    width: 980px; margin: 0 auto;
    h2 {font-size: 40px; color: #111; font-weight: 700; text-transform: uppercase; margin-bottom: 15px;}
    h6 {font-size: 20px; margin-bottom: 80px;}

    .list_src {
        .item {
            display: block; position: relative; margin-bottom: 40px; padding-bottom: 40px; border-bottom: 1px solid #ccc;
            a {
                h4 {font-size: 24px; color: #333; font-weight: 500; margin-bottom: 15px;}
                p {font-size: 18px; line-height: 26px; color: #333; margin-bottom: 0;}
                &:hover {
                    h4 {color: #da2327;}
                }
            }
            &:last-child {
                border-bottom: none; padding-bottom: 0;
            }
        }
    }
}

.pagging {
    position: relative; display: block; text-align: center; margin-top: 30px;font-size: 16px; line-height: 20px;
    a {
        color: #333; font-size: 16px; line-height: 20px; font-weight: 600; position: relative; margin: 0 5px;
        display: inline-block; padding: 0 8px 5px; vertical-align: middle; @include transition(all linear 0.25s);
        &:after {
            content:''; display: block; position: absolute; bottom: 0; height: 2px; background: #ff7200; @include transition(all linear 0.25s);
            left: 0; right: 0; margin: auto; width: 0;
        }
        &:hover {
            color: #ff7200; @include transition(all linear 0.25s);
            &:after {width: 100%;@include transition(all linear 0.25s);}
        }
        &.active {
            color: #ff7200; @include transition(all linear 0.25s);
            &:after {width: 100%;@include transition(all linear 0.25s);}
        }
        &.prev,
        &.next {
            width: 16px; height: 16px; background: url('../images/material/ico_pagging.png') no-repeat left center; padding: 0; vertical-align: top; top: 4px;
            &:hover {
                background-position: right center;
            }
            &:after {display: none;}
        }
        &.prev { margin-right: 20px;}
        &.next {@include transform(rotate(180deg)); margin-left: 20px;}
    }
}

.ui-datepicker {width: 22em;}
.ui-widget {font-size: 13px;}
.ui-datepicker td span, .ui-datepicker td a {padding: 7px 3px;}
.box-share-list{
    position: relative;
    .share-list{
        position: absolute;left: -70px;
        &.fixed{position: fixed;top: 110px;left: auto;}
    }
}
.share-list{
    a{display: block;}
}

.button-group {
  display: flex;
  gap:0 5px; 
}


/* footer
----------------------------------------------------------------------------------------------*/
footer{
    height:110px;background: url(../images/material/bg-footer.jpg) no-repeat;background-size:cover;color:#FFF;
    .wrapper{width:1360px;}
    .left{
        margin:47px 0 0 0;
        nav {float: none; margin-right: 0; display: none;}
        p {
					font-size:13px;color:#FFF; margin-bottom: 0;
					a {
						color: #fff;
						&:hover {
							text-decoration: underline;
						}
					}
				}
    }
    .right{
        margin:47px 0 0 0;
    }
    nav{float:left;margin-right:30px;
        a{
            font-size:13px;color:#FFF;
						&:hover {
							text-decoration: underline;
							color: #fff; 
						}
        }
        span{padding:0 10px;}
    }
    .socmed{
        float: left;margin:-8px 0 0 0;
        a{float: left;margin:0 0 0 10px;@include transition(0.2s linear all);
          img{display: block;}
          &:hover{opacity: 0.6}
        }
    }
}